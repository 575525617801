@import "./override/antd.scss";

:root {
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --color-primary: #63c2cf;
  --color-warning: #ff7875;
  --color-bg: #e7e9f6;
  --color-gray50: #d0d1d2;
  --color-gray9: #999;
  --color-gray20: #393c40;
  --fs-s: 12px;
  --fs-m: 14px;
  --fs-l: 16px;
  --fs-xl: 18px;
  --card-title-bg: #ECF0F4;

  --bezier: cubic-bezier(0.5,0,0,0.75);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  overflow: auto;

  a {
    color: var(--color-primary);
    text-decoration: none;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  // background: rgb(var(--background-start-rgb));
  background: #f9fbfb !important;
}

a[title="站长统计"] {
  display: none;
}
