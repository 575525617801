html {
  .ant-btn-primary {
    background: var(--color-primary);
    border-color: var(--color-primary);
    border-radius: 4px;
  }

  .ant-btn-link {
    color: var(--color-primary);
  }

  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 8px;
  }

  .ant-drawer-mask {
    backdrop-filter: blur(0px);
    transition: background-color .5s ease, backdrop-filter .5s ease, -webkit-backdrop-filter .5s ease;
  }
}
